import './intro.styles.scss';
import Flare from '../../assets/webp/flare-geaa60ee82_640.webp';
import { useTranslation } from 'react-i18next';

const Intro = () => {
    const {t} = useTranslation();

    return (
        <section className='introContainer'>
            <div className='introWrapper'>
                <div className='annual'>
                    <p className='annualSubtitle'>
                        {t("At least")}
                    </p>
                    <h2 className='annualTitle goldHeader'>52</h2>
                    <p className='annualSubtitle annualSubtitleStart'>
                        {t("Trade setups")}<br />
                        {t("per year")}
                    </p>
                </div>
                <div className='weekly'>
                    <h2 className='goldHeader'>{t("ONCE")}</h2>
                    <p className='weeklySubtitle'>{t("a week")}</p>
                </div>
            </div>
            <img src={Flare} className='flareImage' alt=''></img>
        </section>
    );
};

export default Intro;
