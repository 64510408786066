import './trust-component.styles.scss';

export default function TrustSubsectionComponent({data}) {
    return (
        data.link ? 
            <li className='articleItem' dangerouslySetInnerHTML={
                {
                    __html: `<h3 class='sectionTitle'>
                    ${data.subsectionLetter}:</h3><p class='sectionContent'>
                    ${data.content}
                    ${data.link ? ` <a href='${data.link.href}' class='sectionLink' target='_blanck'>
                    ${data.link.name}</a>` : ''}</p>`
                }
                }>
            </li>
        :
            <li className='articleItem'>
                <h3 className='sectionTitle'>{data.subsectionLetter}:</h3>
                    <p className='sectionContent'>{data.content}</p>
            </li>
    )
};
