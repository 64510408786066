import React from "react";
import {Outlet} from "react-router-dom";
import NavigationBar from "../navigation-bar-component/navigation-bar.component";
import FooterComponent from "../../components/footer-component/footer.component";

const Layout = () => {
  return (
    <>
        <NavigationBar />
        <Outlet />
        <FooterComponent />
    </>
  );
};

export default Layout;