import './trades.styles.scss';
import TradeItem from './tradeItem-component/tradeItem.component';
import { useTranslation } from 'react-i18next';

const dataSet = [
    {
        name: 'Ethereum',
        shortName: 'ETH',
        value: '15,43%',
    },
    {
        name: 'Bitcoin',
        shortName: 'BTC',
        value: '26,58%',
    },
    {
        name: 'Verasity',
        shortName: 'VRA',
        value: '21,61%',
    },
]

const TradesComponent = () => {
    const {t} = useTranslation();

    return (
        <section className='tradesContainer' id='trades'>
            <div className='trades'>
                <h3 className='tradesTitle'>{t("Our average ROI on an annual base:")}</h3>
                {
                    dataSet.map((data, index) => {
                        return <TradeItem data={data} index={index} key={`${index}-~${data.name}`} />
                    })
                }
            </div>
            <div className='content'>
                <h2 className='contentTitle'>{t("Trading and learning")}</h2>
                <p className='contentSubtitle'>{t("With no extra nonsense or obligations.")}</p>
                {/* <img src={TradeSVG} alt='' className='tradingSvg'/> */}
            </div>
        </section>
    );
};

export default TradesComponent;
