import './tradeItem.styles.scss';

const TradeItem = ({data, index}) => {
    return (
        <div key={`${index}-~${data.name}`} className='trade'>
            <div className='tradeName'>
                <p>{data.name}</p>
                <p className='tradeName_shortName'>{data.shortName}</p>
            </div>
            <p>{data.value}</p>
        </div>
    );
};

export default TradeItem;
