// import LanguagePicker from '../language-picker-component/language-picker.component';
import './header-links.styles.scss';
import {HashLink as Link} from 'react-router-hash-link';

const HeaderLinks = () => {
    return (
        <div className='header-links'>
            <Link 
                to='/#trades'
                alt='link to trades'
                className="navigation-bar-link"
            >
                Trades
            </Link>
            <Link 
                to='/#discord' 
                alt='link to discord'
                className="navigation-bar-link"
            >
                Discord
            </Link>
            {/* <Link 
                to='/#referral' 
                alt='link to discord'
                className="navigation-bar-link"
            >
                Referral
            </Link> */}
            {/* <LanguagePicker /> */}
        </div>
    );
};

export default HeaderLinks;
