import { useTranslation } from 'react-i18next'; 
import './ctaButton.styles.scss';

const CtaButton = () => {
    const { t } = useTranslation();

    return (
        <a href='https://discord.gg/JgusxAKSKt' target='_blanck' className="ctaButton">
            {t("GET STARTED")}
        </a>
    );
}

export default CtaButton;
