import './App.scss';
import './index.scss';
import Home from './routes/home-component/home.component';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import TrustComponent from './routes/trust-component/trust-component';
import About from './routes/trust-component/about.component';
import Layout from './routes/layout-component/layout.component';
import CoockiePolicyComponent from './routes/trust-component/cookie-policy-component';

const privacyPolicy = [  
  {   "title": "Article 1. Definitions",
      "content": "In this privacy policy, the following terms written with an initial capital letter are used with the following meanings, unless expressly stated otherwise or unless the context otherwise indicates:",
      "definitions": [
        { 
          "term": "Community:", 
          "content": "The closed community managed by The Weekly Trade on the Platform;" 
        }, 
        { 
          "term": "Discord:",
          "content": "The company Discord Inc.;"
        },
        { 
          "term": "Customer:", 
          "content": "The natural person who has entered into an Agreement with The Weekly Trade;" 
        },
        { 
          "term": "Agreement:", 
          "content": "The agreement between The Weekly Trade and the Customer for access to the Community;" 
        },
        { 
          "term": "Personal data:",
          "content": "Data about an identified or identifiable natural person;" 
        },
        { 
          "term": "Platform:",
          "content": "The online platform of Discord available via: ",
          "link": {
            "name": "Discord;",
            "href": "https://discord.com/",
          }
        },
        { 
          "term": "The Weekly Trade:", 
          "content": "The user of this privacy policy: The Weekly Trade, located at Zinkstraat 24 Box A6008, Breda, Netherlands, registered with the Chamber of Commerce under registration number 72681004;"
        },
        { 
          "term": "Website:", 
          "content": "The website of ",
          "link": {
            "name": "The Weekly Trade;",
            "href": "https://theweeklytrade.io/",
          }
        }
      ]
  },
  {
    "title": "Article 2. General, data controller and contact details",
    "sections": [
      {
        "sectionNumber": "2.1",
        "content": "This privacy policy describes how The Weekly Trade processes Personal Data in relation to the Agreements."
      },
      {
        "sectionNumber": "2.2",
        "content": "The data controller is:",
        "extraContent": [
          "The Weekly Trade",
          "Zinkstraat 24 Box A6008",
          "4823 AD Breda",
          "Netherlands",
          "Registration number: 72681004",
          "Email address: info@theweeklytrade.io",
        ]
      },
      {
        "sectionNumber": "2.3",
        "content": "The careful handling of Personal Data is of great importance to The Weekly Trade. With regard to the processing of Personal Data, The Weekly Trade acts in accordance with the General Data Protection Regulation (GDPR)."
      }
    ]
  },
  {
    "title": "Article 3. Collection of Personal Data",
    "sections": [
      {
        "sectionNumber": "3.1",
        "content": "The Weekly Trade processes Personal Data of Customers."
      },
      {
        "sectionNumber": "3.2",
        "content": "The Weekly Trade collects Personal Data from the Customer at the time the Customer enters into the Agreement via the Website."
      }
    ]
  },
  {
    "title": " Article 4. Personal Data",
    "sections": [
      {
        "sectionNumber": "4.1",
        "content": "The Weekly Trade processes the following Personal Data:",
        "subsections": [
          {
            "subsectionLetter": "a",
            "content": "Email address;"
          },
          {
            "subsectionLetter": "b",
            "content": "Payment details;"
          },
          {
            "subsectionLetter": "c",
            "content": "The Personal Data that the Customer has provided to The Weekly Trade, for example via email, such as first name, last name, and telephone number."
          }
        ]
      },
      {
        "sectionNumber": "4.2",
        "content": "For the performance of the Agreement, it is necessary to provide the Personal Data mentioned in article 4.1 sub. a. and b. Without this Personal Data, the Agreement cannot be concluded, and the payment cannot be executed."
      }
    ]
  },
  {
    "title": "Article 5. Retention of Personal Data",
    "sections": [
      {
        "sectionNumber": "5.1",
        "content": "The Weekly Trade will not retain Personal Data longer than necessary to achieve the purposes described in Article 6.1 for which the Personal Data are processed, unless there is a legal obligation that requires longer retention."
      }
    ]
  },
  {
    "title": "Article 6. Purposes and Legal Basis for Processing of Personal Data",
    "sections": [
      {
        "sectionNumber": "6.1",
        "content": "The Weekly Trade processes Personal Data for the following purposes:",
        "subsections": [
          {
            "subsectionLetter": "a",
            "content": "Establishment and execution of the Agreement, such as:",
            "subsections": [
              {
                "subsectionLetter": "i",
                "content": "Providing access to the Community;"
              },
              {
                "subsectionLetter": "ii",
                "content": "Sending email messages to provide information about the Agreement, such as:",
                "subsections": [
                    {"content": "Confirmation of the Agreement;"},
                    {"content": "Confirmation of payment;"}
                ],
              },
            ]
          },
          {
            "subsectionLetter": "b",
            "content": "Communication purposes, such as:",
            "subsections": [
              {
                "subsectionLetter": "i",
                "content": "Processing a question, comment or complaint;"
              },
              {
                "subsectionLetter": "ii",
                "content": "Providing information about the progress of the handling of a question, comment or complaint;"
              },
            ]
          },
          {
            "subsectionLetter": "c",
            "content": "Administrative purposes, such as:",
            "subsections": [
              {
                "subsectionLetter": "i",
                "content": "Executing payment;"
              },
              {
                "subsectionLetter": "ii",
                "content": "Maintaining a customer database;"
              },
            ]
          },
          {
            "subsectionLetter": "d",
            "content": "Sending an email asking if the Customer wishes to subscribe to newsletters from The Weekly Trade;"
          },
          {
            "subsectionLetter": "e",
            "content": "Sending newsletters, see also Article 7."
          }
        ]
      },
      {
        "sectionNumber": "6.2",
        "content": "The legal basis for the processing included in Article 6.1 sub. a., b. and c is the execution of the Agreement."
      },
      {
        "sectionNumber": "6.3",
        "content": "The legal basis for the processing included in Article 6.1 sub. d. is the legitimate interest of The Weekly Trade, namely requesting permission to send newsletters."
      },
      {
        "sectionNumber": "6.4",
        "content": "The legal basis for the processing included in Article 6.1 sub. e. is the Customer's consent."
      },
      {
        "sectionNumber": "6.5",
        "content": "The Weekly Trade processes only the Personal Data that are minimally necessary for the existing purposes. The Weekly Trade strives for minimal data processing."
      },
      {
        "sectionNumber": "6.6",
        "content": "The Weekly Trade will not process Personal Data for other purposes than described in this Privacy Policy."
      },
      {
        "sectionNumber": "6.7",
        "content": "The Weekly Trade does not use automated decision-making."
      }
    ]
  },
  {
    "title": "Article 7. Newsletters",
    "sections": [
      {
        "sectionNumber": "7.1",
        "content": "Newsletters are only sent to a Customer who has subscribed to a newsletter and thus has granted permission for sending newsletters to the email address provided by the Customer. The Customer can unsubscribe from newsletters at any time.",
      },
      {
        "sectionNumber": "7.2",
        "content": "The Customer can unsubscribe from newsletters in the following ways:",
        "subsections": [
          {
            "subsectionLetter": "a",
            "content": "By clicking on the link in the email that includes the newsletter allowing the Customer to unsubscribe from such newsletters. An unsubscribe link is included in every newsletter;"
          },
          {
            "subsectionLetter": "b",
            "content": "By contacting The Weekly Trade, see Article 2.2 for contact details."
          }
        ]
      }
    ],
  },
  {
    "title": "Article 8. Security Measures",
    "sections": [
      {
        "sectionNumber": "8.1",
        "content": "To prevent unauthorized access to Personal Data, loss, theft, and unlawful use of Personal Data, The Weekly Trade has implemented various security measures, including measures against unauthorized access, use, modification, unlawful and accidental destruction, and accidental loss of Personal Data."
      }
    ]
  },
  {
    "title": "Article 9. Provision of Personal Data to Third Parties",
    "sections": [
      {
        "sectionNumber": "9.1",
        "content": "The Weekly Trade will provide the Customer's Personal Data to third parties if:",
        "subsections": [
          {
            "subsectionLetter": "a",
            "content": "It is necessary for the execution of the Agreement. This concerns the following types of parties with whom The Weekly Trade engages: ICT service provider and payment provider. Insofar as the engaged third party is a processor, the agreements regarding the processing by this processor are recorded in a processing agreement or these agreements are agreed upon by means of another legal act, for example through the applicability of the processor's general terms and conditions. Engaged third parties only have access to the Customer’s Personal Data if this is necessary to perform their activities for The Weekly Trade and to the extent legally permitted;"
          },
          {
            "subsectionLetter": "b",
            "content": "The Customer has given permission for the provision;"
          },
          {
            "subsectionLetter": "c",
            "content": "The Weekly Trade is obliged to provide the Personal Data on the basis of:",
            "subsections": [
              {
                "subsectionLetter": "i",
                "content": "Legislation and regulations, for example to the Tax Authorities or to another government agency;"
              },
              {
                "subsectionLetter": "ii",
                "content": "A judicial order or verdict."
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "title": "Article 10. Discord",
    "sections": [
      {
        "sectionNumber": "10.1",
        "content": "In order to access the Community, the Customer must create an account with Discord."
      },
      {
        "sectionNumber": "10.2",
        "content": "Creating an account with Discord and using the Platform is entirely at the Customer's own risk."
      },
      {
        "sectionNumber": "10.3",
        "content": "This Privacy Policy of The Weekly Trade does not apply to the services of Discord. For information on the processing of Personal Data by Discord, the Customer should consult Discord's privacy policy, see: ",
        "link": {
          "name": "Discord privacy",
          "href": "https://discord.com/privacy",
        }
      },
      {
        "sectionNumber": "10.4",
        "content": "The Weekly Trade is never responsible for how Discord handles the Customer's Personal Data."
      }
    ]
  },
  {
    "title": "Article 11. Payroll",
    "sections": [
      {
        "sectionNumber": "11.1",
        "content": "Payment for the Agreement will be made through Payroll. To pay through Payroll, the Customer must have an account with Payroll. The Customer can log in to Payroll with their Discord account.",
      },
      {
        "sectionNumber": "11.2",
        "content": "The use of Payroll's services is entirely at the Customer's own risk.",
      },
      {
        "sectionNumber": "11.3",
        "content": "This privacy policy of The Weekly Trade does not apply to Payroll's services. For information on how Payroll processes Personal Data, the Customer should consult Payroll's privacy policy, available at: ",
        "link": {
          "name": "PayRole.io privacy",
          "href": "https://payrole.io/privacy",
        }
      },
      {
        "sectionNumber": "11.4",
        "content": "The Weekly Trade is not responsible for the way Payroll handles the Customer's Personal Data.",
      }
    ]
  },
  {
    "title": "Article 12. Deletion of Personal Data",
    "sections": [
      {
        "sectionNumber": "12.1",
        "content": "The Weekly Trade will delete Personal Data from its systems without undue delay, in particular if:",
        "subsections": [
          {
            "subsectionLetter": "a",
            "content": "The Personal Data are no longer necessary for the purposes for which they were processed;"
          },
          {
            "subsectionLetter": "b",
            "content": "The Customer objects to the processing of their Personal Data and this objection is justified;"
          },
          {
            "subsectionLetter": "c",
            "content": "The Personal Data is incorrect or outdated."
          }
        ]
      },
      {
        "sectionNumber": "12.2",
        "content": "The Weekly Trade is not obliged to delete Personal Data if one of the situations described in the law applies, in which the \"right to be forgotten\" does not apply."
      }
    ]
  },
  {
    "title": "Article 13. Right of access, right to data portability, rectification and erasure",
    "sections": [
      {
        "sectionNumber": "13.1",
        "content": "Upon request, The Weekly Trade will provide the Customer with access to the Personal Data that The Weekly Trade holds about them and will provide the Customer with a free copy of this Personal Data in such a way that the Customer can provide it to a third party themselves."
      },
      {
        "sectionNumber": "13.2",
        "content": "The Weekly Trade offers the Customer the opportunity to have any incorrect data that The Weekly Trade holds about them corrected or deleted free of charge."
      }
    ]
  },
  {
    "title": "Article 14. Objection",
    "sections": [
      {
        "sectionNumber": "14.1",
        "content": "The Customer may object to the processing of their Personal Data by The Weekly Trade if the Customer has a good reason for doing so based on their specific situation. After The Weekly Trade has received the objection from the Customer, The Weekly Trade will stop processing the Customer's Personal Data unless The Weekly Trade has compelling legitimate grounds for the processing that override the interests, rights and freedoms of the Customer."
      }
    ]
  },
  {
    "title": "Article 15. Right to Restriction of Processing",
    "sections": [
      {
        "sectionNumber": "15.1",
        "content": "If the Customer has submitted a request for modification, supplementation, or erasure of their Personal Data or has objected to the processing of their Personal Data, and the processing of that request or objection takes some time, the Customer may request The Weekly Trade to restrict the processing of their Personal Data."
      }
    ]
  },
  {
    "title": "Article 16. Consent",
    "sections": [
      {
        "sectionNumber": "16.1",
        "content": "The Customer may withdraw the consent given for the processing of their Personal Data at any time, as stated in Article 7. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal."
      }
    ]
  },
  {
    "title": "Article 17. Personal Data Rights",
    "sections": [
      {
      "sectionNumber": "17.1",
      "content": "For:",
      "subsections": [
        {
          "subsectionLetter": "a",
          "content": "Questions about how The Weekly Trade processes Personal Data;"
        },
        {
          "subsectionLetter": "b",
          "content": "Exercising any of the rights described in Articles 13, 14, or 15;"
        },
        {
          "subsectionLetter": "c",
          "content": "Reporting (suspected) misuse of the Customer's Personal Data processed by The Weekly Trade, the Customer can contact The Weekly Trade, as indicated in Article 2.2 for contact details."
        }
        ]
      },
      {
        "sectionNumber": "17.2",
        "content": "If the Customer makes use of any of the rights described in Articles 13, 14, or 15, then:",
        "subsections": [
          {
            "subsectionLetter": "a",
            "content": "If The Weekly Trade is unsure about the identity of the person making the request, The Weekly Trade may ask for additional information;"
          },
          {
            "subsectionLetter": "b",
            "content": "The Weekly Trade shall inform the Customer of the outcome of the request within one month of receipt."
          }
        ]
      },
      {
        "sectionNumber": "17.3",
        "content": "If The Weekly Trade corrects, supplements or deletes the Customer's Personal Data, terminates or restricts the processing of the Personal Data, or processes a withdrawn consent, then The Weekly Trade shall inform the Customer of this."
      },
      {
        "sectionNumber": "17.4",
        "content": "If the Customer believes that the processing of their Personal Data by The Weekly Trade is not in accordance with this Privacy Policy and/or applicable laws and regulations, the Customer can file a complaint with the Dutch Data Protection Authority or with a supervisory authority in the country where the Customer resides."
      }
    ]
  },
  {
    "title": "Article 18. Cookies",
    "sections": [
      {
        "sectionNumber": "18.1",
        "content": "The Website uses cookies. For more information on the use of cookies, the Customer may consult The Weekly Trade's cookie policy, available at:",
        "link": {
          "name": "The Weekly Trade Cookie policy",
          "href": "https://theweeklytrade.io/cookie-policy",
        }
      }
    ]
  },
  {
    "title": "Article 19. Amendments",
    "sections": [
      {
        "sectionNumber": "19.1",
        "content": "The Weekly Trade reserves the right to unilaterally make changes to this privacy policy, for example, due to a change in the law. The latest version of The Weekly Trade's privacy policy is available on the ",
        "link": {
          "name": "Website.",
          "href": "https://theweeklytrade.io/privacy-rules",
        }
      }
    ]
  },
  {
    "title": 'Article 20. Third-party Privacy Policies',
    "sections": [
      {
        "sectionNumber": '20.1',
        "content": 'This Privacy Policy does not apply to third-party websites that are linked to the Website, such as social media websites. The Weekly Trade accepts no responsibility or liability for how these websites handle Personal Data. For more information on how these third parties handle Personal Data, the privacy policy of the relevant website should be consulted.'
      }
    ]
  }
];

const TermsAndConditions = [
  {
    "title": "Article 1. Definitions",
    "content": "In these general terms and conditions, the following terms written with an initial capital letter are used with the following meanings, unless expressly stated otherwise or unless the context indicates otherwise:",
    "definitions": [
      {
        "term": "Community:",
        "content": "The private community managed by The Weekly Trade on the Platform;"
      },
      {
        "term": "Discord:",
        "content": "The company Discord Inc.;"
      },
      {
        "term": "Customer:",
        "content": "The natural person who has entered into or wishes to enter into an agreement with The Weekly Trade;"
      },
      {
        "term": "Materials:",
        "content": "The educational material (trade setups) published by The Weekly Trade in the Community;"
      },
      {
        "term": "Agreement:",
        "content": "The agreement between The Weekly Trade and the Customer for access to the Community;"
      },
      {
        "term": "Platform:",
        "content": "The online platform of Discord available via: ",
        "link": {
          "name": "Discord;",
          "href": "https://discord.com/"
        }
      },
      {
        "term": "Written:",
        "content": "Written or via email;"
      },
      {
        "term": "The Weekly Trade:",
        "content": "The user of these general terms and conditions: The Weekly Trade located at Zinkstraat 24 Box A6008, Breda, Netherlands, registered with the Chamber of Commerce under registration number 72681004;"
      },
      {
        "term": "Website:",
        "content": "The website of ",
        "link": {
          "name": "The Weekly Trade;",
          "href": "https://theweeklytrade.io/"
        }
      }
    ]
  },
  {
    "title": "Article 2. General",
    "sections": [
      {
        "sectionNumber": "2.1",
        "content": "These general terms and conditions apply to any offer from The Weekly Trade and to any Agreement."
      },
      {
        "sectionNumber": "2.2",
        "content": "Deviating terms and conditions only form part of the Agreement to the extent that those terms and conditions have been expressly agreed upon in writing."
      },
      {
        "sectionNumber": "2.3",
        "content": "These general terms and conditions are published on the Website."
      },
      {
        "sectionNumber": "2.4",
        "content": "If one or more provisions in these general terms and conditions are null and void or are annulled, the other provisions of these general terms and conditions shall remain fully applicable. The null and void or annulled provisions will be replaced by The Weekly Trade, whereby the purpose and intent of the original provision(s) will be taken into account as much as possible."
      },
      {
        "sectionNumber": "2.5",
        "content": "The Weekly Trade has the right to amend these general terms and conditions and declare the amended general terms and conditions applicable to the existing Agreement. The Customer shall be notified in writing of the amended general terms and conditions and the date on which they will come into effect. If the Customer does not agree to the amended general terms and conditions, the Customer has the right to terminate the Agreement with immediate effect on the day on which the amended general terms and conditions come into effect."
      },
      {
        "sectionNumber": "2.6",
        "content": "If The Weekly Trade does not require strict compliance with these general terms and conditions at all times, this does not mean that the provisions thereof are not applicable or that The Weekly Trade would lose its right to demand strict compliance with the provisions of these general terms and conditions in other cases."
      }
    ]
  },
  {
    "title": "Article 3. Offer and prices",
    "sections": [
      {
        "sectionNumber": "3.1",
        "content": "Any offer made by The Weekly Trade is non-binding."
      },
      {
        "sectionNumber": "3.2",
        "content": "Obvious errors or mistakes on the Website do not bind The Weekly Trade."
      },
      {
        "sectionNumber": "3.3",
        "content": "The prices for access to the Community are clearly stated on the Website and are in USD and inclusive of VAT."
      },
      {
        "sectionNumber": "3.4",
        "content": "The Weekly Trade has the right to adjust its prices from time to time. Therefore, the prices mentioned do not automatically apply to future Agreements. The Client will be notified in writing at least 2 months prior to the effective date of a price change and of the date of entry into force. If the Client has entered into an Agreement for a certain period of time, a price change will take effect when the Agreement is extended indefinitely. If the Client does not agree with the price change, the Client may terminate the Agreement in accordance with Article 5."
      }
    ]
  },
  {
    "title": "Article 4. Conclusion of the Agreement",
    "sections": [
      {
        "sectionNumber": "4.1",
        "content": "In order to access the Community, the Customer must register with The Weekly Trade."
      },
      {
        "sectionNumber": "4.2",
        "content": "The Agreement is concluded the moment when the Customer has successfully completed the registration process via the Website."
      },
      {
        "sectionNumber": "4.3",
        "content": "The Agreement can only be concluded via the Website after the Customer has clicked to accept these general terms and conditions."
      },
      {
        "sectionNumber": "4.4",
        "content": "After the Agreement has been concluded, the Customer will receive confirmation by email."
      }
    ]
  },
  {
    "title": "Article 5. Duration, Extension, and Termination",
    "sections": [
      {
        "sectionNumber": "5.1",
        "content": "At the conclusion of the Agreement, the Customer can choose between an Agreement for 3 months or an Agreement for 1 year."
      },
      {
        "sectionNumber": "5.2",
        "content": "After the term has expired, the Agreement will be automatically extended for an indefinite period, unless the Customer terminates the Agreement towards the end of the term, taking into account a notice period of 1 month."
      },
      {
        "sectionNumber": "5.3",
        "content": "The Customer may terminate the Agreement for an indefinite period at any time, taking into account a notice period of 1 month."
      },
      {
        "sectionNumber": "5.4",
        "content": "If the Customer terminates the Agreement for an indefinite period and has paid in advance for a certain period, then a proportion of the prepaid amount will be refunded to the Customer, based on the period during which the Customer had access to the Community."
      }
    ]
  },
  {
    "title": "Article 6. Materials and Discord",
    "sections": [
      {
        "sectionNumber": "6.1",
        "content": "The Weekly Trade publishes new Materials weekly in the Community."
      },
      {
        "sectionNumber": "6.2",
        "content": "The Weekly Trade can make changes to the Materials at any time."
      },
      {
        "sectionNumber": "6.3",
        "content": "To access the Community, the Client must create an account on Discord."
      },
      {
        "sectionNumber": "6.4",
        "content": "Creating an account on Discord and using the Platform is entirely at the Customer’s own risk."
      },
      {
        "sectionNumber": "6.5",
        "content": "The terms and conditions of Discord apply to the use of the Platform, see: ",
        "link": {
          "name": "Discord terms",
          "href": "https://discord.com/terms",
        }
      },
      {
        "sectionNumber": "6.6",
        "content": "It is not possible to chat with The Weekly Trade on the Platform."
      }
    ]
  },
  {
    "title": "Article 7. Right of Withdrawal",
    "sections": [
      {
        "sectionNumber": "7.1",
        "content": "The Customer has the right to cancel the Agreement within 14 days after the conclusion of the Agreement."
      },
      {
        "sectionNumber": "7.2",
        "content": "If the Customer wishes to exercise their right of withdrawal, they must make this known to The Weekly Trade expressly within 14 days after the conclusion of the Agreement, for example by sending an email to info@theweeklytrade.io."
      },
      {
        "sectionNumber": "7.3",
        "content": "If The Weekly Trade has started to perform the Agreement at the express request of the Customer during the withdrawal period, the Customer will owe The Weekly Trade an amount proportional to that part of the obligation that has been fulfilled by The Weekly Trade at the time the Customer exercises their right of withdrawal, compared to the full performance of the obligation."
      },
      {
        "sectionNumber": "7.4",
        "content": "If the Customer exercises their right of withdrawal, The Weekly Trade will reimburse the costs already paid by the Customer for the Agreement or part thereof, if Article 7.3 applies, within 14 days."
      },
      {
        "sectionNumber": "7.5",
        "content": "The Weekly Trade will reimburse the Customer using the same payment method as used by the Customer for the original transaction, unless the Customer explicitly agrees to a different payment method. No fees will be charged for the reimbursement."
      }
    ]
  },
  {
    "title": "Article 8. Execution of the Agreement",
    "sections": [
      {
        "sectionNumber": "8.1",
        "content": "The Weekly Trade has an obligation to make an effort based on the concluded Agreement, and not an obligation to achieve results. The Weekly Trade is not an investment advisor."
      },
      {
        "sectionNumber": "8.2",
        "content": "The Weekly Trade has the right to engage third parties, use services of third parties, and have the Agreement executed entirely or partially by third parties without notifying the Client."
      },
      {
        "sectionNumber": "8.3",
        "content": "Guarantees, such as investment results, income guarantees, or value increases, are never granted by The Weekly Trade. Information published on the Website and (commercial and/or promotional) texts on social media channels of The Weekly Trade should never be considered a guarantee."
      },
      {
        "sectionNumber": "8.4",
        "content": "The Materials are never to be considered investment advice. The Materials are only made available to the Customer for educational purposes."
      }
    ]
  },
  {
    "title": "Article 9. Obligations of the Customer",
    "sections": [
      {
        "sectionNumber": "9.1",
        "content": "The Customer decides for themselves and on their own responsibility whether to use the Materials and which (legal) actions they take based on the Materials, such as investment decisions."
      },
      {
        "sectionNumber": "9.2",
        "content": "The Customer is at all times responsible for their own financial position."
      },
      {
        "sectionNumber": "9.3",
        "content": "All consequences arising from accessing the Community and using the Materials are at the expense and risk of the Customer."
      },
      {
        "sectionNumber": "9.4",
        "content": "The Customer is responsible for complying with laws and regulations in the country where the Customer resides."
      },
      {
        "sectionNumber": "9.5",
        "content": "The Customer should be aware that investing can have both a positive and a negative outcome. The Weekly Trade does not guarantee that the Customer will achieve certain results with access to the Community. The Weekly Trade only provides general information for education, and this information is not advice. The Weekly Trade is in no way responsible for the investment results of the Customer. The Customer is fully responsible for their own (legal) actions."
      }
    ]
  },
  {
    "title": "Article 10. Payment and Payrole",
    "sections": [
      {
        "sectionNumber": "10.1",
        "content": "Payment for the fixed-term Agreement is due in advance."
      },
      {
        "sectionNumber": "10.2",
        "content": "Payment for the indefinite-term Agreement is due annually in advance."
      },
      {
        "sectionNumber": "10.3",
        "content": "Payment for the Agreement is made through Payrole. To make payments through Payrole, the Customer must have an account with Payrole. The Customer can log in to Payrole using their Discord account."
      },
      {
        "sectionNumber": "10.4",
        "content": "The use of Payrole's services is entirely at the Customer's own risk."
      },
      {
        "sectionNumber": "10.5",
        "content": "These general terms and conditions of The Weekly Trade do not apply to Payrole's services. Payrole's general terms and conditions apply, see:",
        "link": {
          "name": "PayRole.io terms",
          "href": "https://payrole.io/terms",
        }
      },
      {
        "sectionNumber": "10.6",
        "content": "After the Customer has successfully paid for the Agreement or for the renewal of the Agreement, a payment confirmation will be sent to the Customer via email."
      }
    ]
  },
  {
    "title": "Article 11. Referral Program",
    "sections": [
      {
        "sectionNumber": "11.1",
        "content": "If the Customer uses The Weekly Trade's referral program, the Customer can share the Platform with a unique link. If someone clicks on this link and subsequently enters into an Agreement, €0.50 will be credited to the Customer's Payrole environment. These amounts cannot be exchanged for cash with The Weekly Trade and can only be spent within the Payrole environment."
      }
    ]
  },
  {
    "title": "Article 12. Liability and statute of limitations",
    "sections": [
      {
        "sectionNumber": "12.1",
        "content": "The Weekly Trade cannot be held liable for any damage that is a direct or indirect result of:",
        "subsections": [
          {
            "subsectionLetter": "a",
            "content": "An event that is beyond its control and thus cannot be attributed to its actions or omissions, as described in Article 13, among others;"
          },
          {
            "subsectionLetter": "b",
            "content": "Any act or omission of the Customer."
          }
        ]
      },
      {
        "sectionNumber": "12.2",
        "content": "The Weekly Trade is not liable for any damage resulting from the temporary unavailability of the Platform, for example due to a malfunction or maintenance at Discord. The Weekly Trade has no control over the operation of the Platform."
      },
      {
        "sectionNumber": "12.3",
        "content": "The Weekly Trade is not liable for the actions and/or omissions of Discord. If Discord fails to fulfil its obligations to the Customer or acts unlawfully towards the Customer, the Customer must address Discord and not The Weekly Trade."
      },
      {
        "sectionNumber": "12.4",
        "content": "The use of the Materials and the execution of (legal) actions based on the Materials is entirely at the Customer's own risk. The Weekly Trade is not liable for any financial, mental, or any other damage, whether direct or indirect, suffered by the Customer as a result of using the Materials or by actions taken based on the Materials."
      },
      {
        "sectionNumber": "12.5",
        "content": "The Weekly Trade is not liable if access to the Community has not resulted in the desired outcome for the Customer."
      },
      {
        "sectionNumber": "12.6",
        "content": "The Weekly Trade is not liable for damage caused by hackers or a cyber attack."
      },
      {
        "sectionNumber": "12.7",
        "content": "The Weekly Trade is not liable for any damage resulting from incorrect interpretation of the Materials."
      },
      {
        "sectionNumber": "12.8",
        "content": "If the Customer has carried out (legal) actions based on the Materials that are contrary to laws or regulations, The Weekly Trade is never liable for the consequences that result from them."
      },
      {
        "sectionNumber": "12.9",
        "content": "The Weekly Trade is never liable for indirect damage, including consequential damage, loss of profit, missed income, loss of investment, accumulated debts, loss of revenue, reputation damage, business damage, missed savings, labor costs, delay damage, imposed fines or other government sanctions, and damage caused by business interruption."
      },
      {
        "sectionNumber": "12.10",
        "content": "If The Weekly Trade were to be liable for any damage or if the above-mentioned liability limitation is not allowed or declared inapplicable by a competent court of law, The Weekly Trade's liability is limited to the amount that the Customer has paid for the Agreement for a period of up to 1 year."
      },
      {
        "sectionNumber": "12.11",
        "content": "Any legal action for non-compliance with the Agreement or an action and/or omission of The Weekly Trade shall be time-barred after 1 year from the date on which the Customer discovered or should reasonably have discovered the damage."
      }
    ]
  },
  {
    "title": "Article 13. Force majeure",
    "sections": [
      {
        "sectionNumber": "13.1",
        "content": "The Weekly Trade is not obliged to fulfill any obligation towards the Customer if hindered by circumstances that are not due to its fault and do not fall under the law, a legal act or commonly accepted practices."
      },
      {
        "sectionNumber": "13.2",
        "content": "Force majeure, in these general terms and conditions, means in addition to what is understood by law and case law, all causes from outside, foreseen or unforeseen, over which The Weekly Trade cannot exert any influence but which prevent The Weekly Trade from fulfilling its obligations. Force majeure shall include, but not be limited to, a failure of a third party, disruption in a service, website or software of a third party, such as the Platform, government measures, changes in laws or regulations, power outage, war, terrorism, riots, fire, theft, data loss due to computer malfunction, virus infection or computer hacking by third parties, cyber attack, as well as any other situation over which The Weekly Trade cannot exercise decisive control."
      }
    ]
  },
  {
    "title": "Article 14. Intellectual Property Rights",
    "sections": [
      {
        "sectionNumber": "14.1",
        "content": "The intellectual property rights to the Materials are owned by The Weekly Trade."
      },
      {
        "sectionNumber": "14.2",
        "content": "The Customer must always respect the intellectual property rights of The Weekly Trade."
      },
      {
        "sectionNumber": "14.3",
        "content": "Without prior written consent from The Weekly Trade, the Customer is not allowed to edit, reproduce, disclose, provide to third parties, or make available to third parties for inspection, whether or not for a fee, the Materials in whole or in part, through any medium."
      },
      {
        "sectionNumber": "14.4",
        "content": "If the Customer infringes the intellectual property rights of The Weekly Trade, the Customer is liable for all damage suffered by The Weekly Trade, including loss of revenue."
      },
      {
        "sectionNumber": "14.5",
        "content": "If The Weekly Trade finds that the Customer is or has been in breach of the intellectual property rights of The Weekly Trade, The Weekly Trade has the right to block access to the Community, without the Customer being entitled to compensation."
      }
    ]
  },
  {
    "title": "Article 15. Personal Data",
    "sections": [
      {
        "sectionNumber": "15.1",
        "content": "The Weekly Trade processes personal data in accordance with the General Data Protection Regulation (GDPR). For more information about the processing of personal data, the Customer can consult The Weekly Trade's privacy policy at: ",
        "link": {
          "name": "The Weekly Trade privacy rules",
          "href": "https://theweeklytrade.io/privacy-rules",
        }
      }
    ]
  },
  {
    "title": "Article 16. Questions, complaints, and communication",
    "sections": [
        {
            "sectionNumber": "16.1",
            "content": "Questions and/or complaints can be addressed to The Weekly Trade via the email address info@theweeklytrade.io."
        },
        {
            "sectionNumber": "16.2",
            "content": "Questions and/or complaints will be handled by The Weekly Trade as soon as possible."
        },
        {
            "sectionNumber": "16.3",
            "content": "The liability of The Weekly Trade is at all times limited to what is stated in Article 12."
        },
        {
            "sectionNumber": "16.4",
            "content": "Communication with The Weekly Trade is conducted in English."
        }
    ]
  },
  {
    "title": "Article 17. Expiry term",
    "sections": [
      {
        "sectionNumber": "17.1",
        "content": "Unless otherwise specified in these general terms and conditions, any claim rights of the Customer against The Weekly Trade, for whatever reason, will in any case expire after 1 year from the moment the Customer became or could reasonably have become aware of the existence of these rights."
      }
    ]
  },
  {
    "title": "Article 18. Contract takeover and cessation of business activities",
    "sections": [
      {
        "sectionNumber": "18.1",
        "content": "If The Weekly Trade transfers (part of) its business or transfers its legal relationship resulting from the Agreement, the Customer grants, by entering into the Agreement, prior consent for the takeover of the Agreement by the party that takes over (part of) the business from The Weekly Trade or takes over the legal relationship, and the Customer is obliged to cooperate with such a contract takeover."
      },
      {
        "sectionNumber": "18.2",
        "content": "If The Weekly Trade ceases its business or the Community and no transfer takes place as described in Article 18.1, The Weekly Trade will terminate the Agreement with due observance of a notice period of at least 1 month. In such a case, settlement will be made in proportion."
      },
      {
        "sectionNumber": "18.3",
        "content": "The Weekly Trade is not liable for any damages suffered by the Customer due to The Weekly Trade partially or completely stopping its business activities or the Community."
      }
    ]
  },
  {
    "title": "Article 19. Applicable law and jurisdiction",
    "sections": [
      {
        "sectionNumber": "19.1",
        "content": "Dutch law applies to these general terms and conditions, the Agreement and all legal acts between the Customer and The Weekly Trade."
      },
      {
        "sectionNumber": "19.2",
        "content": "Parties may only make an appeal to a relevant court of law after they have made every effort to settle a dispute through mutual consultation."
      },
      {
        "sectionNumber": "19.3",
        "content": "All disputes between the Customer and The Weekly Trade will be settled by the court in the Netherlands that has jurisdiction where The Weekly Trade is located. The Customer has the option to choose the court authorized by law to settle the dispute within 1 month after The Weekly Trade has made a written appeal under this article."
      }
    ]
  }
];

const refundPolicy = [
  {
    "content": "We are committed to ensuring your satisfaction with our platform and services. If, as a new member, you are not completely satisfied, we offer a 100% refund guarantee. Simply cancel within 14 days and notify us by sending an email to info@theweeklytrade.io.",
  },
];

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />}/>
          <Route path='about-us' element={<About />} id='#about'/>
          <Route path='general-terms-conditions' element={<TrustComponent title='General Terms and conditions' data={TermsAndConditions} />} id='#terms-and-conditions' />
          <Route path='privacy-rules' element={<TrustComponent title='Privacy Policy' data={privacyPolicy} />} id='#privacy-rules'/>
          <Route path='refund-policy' element={<TrustComponent title='Refund Policy' data={refundPolicy} />} id='#refund-policy'/>
          <Route path='cookie-policy' element={<CoockiePolicyComponent />} id='#cookie-policy'/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
