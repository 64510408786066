import { List, XCircle } from "phosphor-react";
import DropDown from '../../components/navigation-components/dropdown-component/dropdown.component';
import { useState } from 'react';


const MobileMenu = () => {
    const [isShown, setIsShown] = useState(false);

    /**
     * This function sets the isShown variable to true or false depending of what 
     * kind of boolean value it currently is in the state.
     * This is to display the dropdown when the user wants to open it.
     */
    const handleClick = () => {
        setIsShown(current => !current);
    };

    return (
        <>
            {
                isShown ?
                    <XCircle
                        className='mobileMenu'
                        onClick={handleClick}
                        size={30} 
                        color="#65d66e" 
                        weight="thin"
                    />
                :
                    <List 
                        className='mobileMenu mobileBurger'
                        onClick={handleClick}
                        size={30}
                        color="#65d66e"
                        weight="bold"
                    />
            }
            { isShown ? <DropDown isShown={isShown} handleClick={handleClick}/> : '' }
        </>
    );
};

export default MobileMenu;