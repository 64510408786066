import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            es: {
                translation: {
                    "Pro trade setups now also available for you": "Plataformas de comercio professionales ahora también disponibles para usted",
                    "The platform for all those out there who can’t afford expensive community groups about trading.": "La plataforma para todos aquellos que no pueden costearse las comunidades de comercializacion.",
                    "We make it easy and simple for YOU and give you an afforable chance to gain knowledge from experienced professional traders.": "Lo hacemos fácil y sencillo para TI y te damos una oportunidad asequible de adquirir conocimiento de traders traders experimentados.",
                    "GET STARTED": "COMENZAR",
                    "Trade setups": "plataformas comerciales",
                    "per year": "por año",
                    "ONCE": "UNA VEZ",
                    "a week": "una semana",
                    "Our average ROI on an annual base:": "Nuestra ganancia promedio anual:",
                    "Trading and learning": "Comercializar y aprender",
                    "With no extra nonsense or obligations.": "Sin compromisos ni obligaciones",
                    "Stay updated, stay one step ahead. We like to keep it clean and simple. Which means there is no place for chatting or debating in our private channel. One way information. One educational trade a week, every week!": "Mantente actualizado, siempre un paso adelante. Nos gusta mantenerlo claro y simple. No hay lugar para chatear o debatir en nuestro canal privado. Información unidireccional. Una plataforma comercial por semana, ¡todas las semanas!",
                    "CHOOSE": "ELIGE",
                    "CHOOSE YOUR PLAN": "ELIGE TU PLAN",
                    "Trust": "Reglas",
                    "Privacy Rules": "Reglas de privacidad",
                    "Terms & conditions": "Términos y condiciones",
                    "Let's stay social": "Siguenos en redes sociales",
                    "Quarterly": "Trimestral",
                    "Get exclusive access to our community for 3 months.": "Obtén acceso exclusivo a nuestra comunidad por 3 meses.",
                    "Get 13 educational trade setups for 0.38 cents each.": "Obtén 13 plataformas comerciales educativas por 0.38 centavos cada una.",
                    "Get access to our exclusive discord group.": "Obtén acceso a nuestro grupo exclusivo de discord.",
                    "Pay every quarter.": "Pagar cada trimestre.",
                    "Annual": "Anual",
                    "Stay informed with real-time updates and expert analysis all year round!": "Mantente informado con actualizaciones en tiempo real y análisis de expertos durante todo el año!",
                    "Get 52 educational trade setups for only 0.25 cents each.": "Obtén 52 plataformas comerciales educativas por solo 0.25 centavos cada una.",
                    "One-time payment, full package.": "Pago único, paquete completo.",
                    "Most chosen worldwide.": "Elegido en gran parte del mundo.",
                    "Best value for money.": "Mejor relación calidad-precio.",
                    "Welcome to The Weekly Trade! We are dedicated to providing educational and informational trade setups for our members. Our team of experts shares weekly trade setups, with a focus on keeping the information accessible and informative for all.": "Bienvenido a The Weekly Trade! Nos dedicamos a proporcionar plataformas comerciales educativas e informativas para nuestros miembros. Nuestro equipo de expertos comparte plataformas comerciales semanales, con un enfoque en mantener la información accesible e informativa para todos.",
                    "We started this The Weekly Trade discord server with the aim of providing a space where traders could receive high-quality trade setups without having to pay exorbitant fees. Our belief is that everyone should have access to the information they need to succeed in the markets, and that's why we are committed to providing it for a small fee.": "Comenzamos este servidor de Discord de The Weekly Trade con el objetivo de proporcionar un espacio donde los comerciantes puedan recibir plataformas comerciales de alta calidad sin tener que pagar tarifas exorbitantes. Nuestra creencia es que todos deberían tener acceso a la información que necesitan para tener éxito en los mercados, y es por eso que nos comprometemos a proporcionarlo por una pequeña tarifa.",
                    "Please note that all the trade setups provided in our discord server are for educational purposes only and are not financial advice. We encourage all members to conduct their own research and make their own investment decisions.": "Tenga en cuenta que todas las plataformas comerciales proporcionadas en nuestro servidor de Discord son solo con fines educativos y no son consejos financieros. Alentamos a todos los miembros a realizar su propia investigación y tomar sus propias decisiones de inversión.",
                    "We hope you find our community a valuable resource in your trading journey and we look forward to sharing our insights and experiences with you.": "Esperamos que encuentres nuestra comunidad un recurso valioso en tu viaje comercial y esperamos compartir nuestras perspectivas y experiencias contigo.",
                    "About us": "Acerca de nosotros",
                    "we are working on it": "Estamos trabajando en ello",
                    "Join our referral program and invite your friends to our Discord community.": "Únete a nuestro programa de referidos e invita a tus amigos a nuestra comunidad de Discord.",
                    "For each successful referral, you'll earn 50 cents. Don't miss out on this opportunity to earn rewards while helping us grow!": "Por cada referencia exitosa, ganarás 50 centavos. ¡No te pierdas esta oportunidad de ganar recompensas mientras nos ayudas a crecer!",
                    "Save up your credits and apply them on your next purchase of one of our plans or any other product you like. Start saving now and enjoy even greater savings on your favorite products!": "Ahorra tus créditos y aplícalos en tu próxima compra de uno de nuestros planes o cualquier otro producto que te guste. ¡Comienza a ahorrar ahora y disfruta de ahorros aún mayores en tus productos favoritos!",
                }
            },
            // pt: {
            //     translation: {
            //         "Pro trade setups now also available for you": "Configurações comerciais avançadas agora também estão disponíveis para você",
            //         "The platform for all those out there who can’t afford expensive community groups about trading.": "A plataforma para todos aqueles que não podem pagar grupos de comunidade caros sobre negociação.",
            //         "We make it easy and simple for YOU and give you an afforable chance to gain knowledge from experienced professional traders.": "Nós tornamos fácil e simples para VOCÊ e damos uma oportunidade acessível de adquirir conhecimento de traders profissionais experientes.",
            //         "GET STARTED": "COMECE AGORA",
            //         "Trade setups": "Configurações de negociação",
            //         "per year": "por ano",
            //         "ONCE": "UMA VEZ",
            //         "a week": "por semana",
            //         "Our average PNL on a annual base:": "Nossa média de PNL em base anual:",
            //         "Trading and learning": "Negociando e aprendendo",
            //         "With no extra nonsense or obligations.": "Sem enrolações extras ou obrigações",
            //         "Stay updated, stay one step ahead. We like to keep it clean and simple. Which means there is no place for chatting or debating in our private channel. One way information. One educational trade a week, every week!": "Fique atualizado, fique um passo à frente. Gostamos de manter tudo limpo e simples. Isso significa que não há lugar para bate-papo ou debate em nosso canal privado. Informação de uma via. Um comércio educacional por semana, toda semana!",
            //         "CHOOSE": "ESCOLHA",
            //         "CHOOSE YOUR PLAN": "ESCOLHA SEU PLANO",
            //         "Trust": "Confiança",
            //         "Privacy Rules": "Regras de privacidade",
            //         "Terms & conditions": "Termos e condições",
            //         "Let's stay social": "Vamos ficar sociais",
            //         "Quarterly": "Trimestral",
            //         "Get exclusive access to our community for 3 months.": "Obtenha acesso exclusivo à nossa comunidade por 3 meses.",
            //         "Get 13 educational trade setups for 0.30 cents each.": "Obtenha 13 configurações comerciais educacionais por 0,30 centavos cada.",
            //         "Get access to our exclusive discord group.": "Obtenha acesso ao nosso grupo exclusivo do Discord.",
            //         "Pay every quarter.": "Pague a cada trimestre.",
            //         "annual": "Anualmente",
            //         "Stay informed with real-time updates and expert analysis all year round!": "Mantenha-se informado com atualizações em tempo real e análise de especialistas durante todo o ano!",
            //         "Get 52 educational trade setups for only 0.25 cents each.": "Obtenha 52 configurações de negociação educacionais por apenas 0,25 centavos cada.",
            //         "One-time payment, full package.": "Pagamento único, pacote completo.",
            //         "Most chosen worldwide.": "Escolhido em todo o mundo.",
            //         "Best value for money.": "Melhor relação custo-benefício.",
            //         "Welcome to The Weekly Trade! We are dedicated to providing educational and informational trade setups for our members. Our team of experts shares weekly trade setups, with a focus on keeping the information accessible and informative for all.": "Bem-vindo ao The Weekly Trade! Estamos dedicados a fornecer configurações comerciais educativas e informativas para nossos membros. Nossa equipe de especialistas compartilha configurações comerciais semanais, com o objetivo de manter a informação acessível e informativa para todos.",
            //         "We started this The Weekly Trade discord server with the aim of providing a space where traders could receive high-quality trade setups without having to pay exorbitant fees. Our belief is that everyone should have access to the information they need to succeed in the markets, and that's why we are committed to providing it for a small fee.": "Nós iniciamos este servidor do Discord The Weekly Trade com o objetivo de fornecer um espaço onde os comerciantes possam receber configurações comerciais de alta qualidade sem ter que pagar taxas exorbitantes. Nossa crença é de que todos devem ter acesso à informação de que precisam para ter sucesso nos mercados, e é por isso que nos comprometemos a fornecer por uma pequena taxa.",
            //         "Please note that all the trade setups provided in our discord server are for educational purposes only and are not financial advice. We encourage all members to conduct their own research and make their own investment decisions.": "Por favor, observe que todas as configurações de negociação fornecidas em nosso servidor do Discord são apenas para fins educacionais e não são conselhos financeiros. Encorajamos todos os membros a realizarem sua própria pesquisa e tomarem suas próprias decisões de investimento.",
            //         "We hope you find our community a valuable resource in your trading journey and we look forward to sharing our insights and experiences with you.": "Esperamos que você encontre nossa comunidade um recurso valioso em sua jornada de negociação e esperamos compartilhar nossas percepções e experiências com você.",
            //         "About us": "Sobre nós",
            //         "we are working on it": "Estamos trabalhando nisso",
            //     }
            // },
            // fr: {
            //     translation: {
            //         "Pro trade setups now also available for you": "Les configurations commerciales Pro sont maintenant également disponibles pour vous",
            //         "The platform for all those out there who can’t afford expensive community groups about trading.": "La plate-forme pour tous ceux qui ne peuvent pas se permettre de coûteux groupes communautaires sur le trading.",
            //         "We make it easy and simple for YOU and give you an afforable chance to gain knowledge from experienced professional traders.": "Nous le rendons facile et simple pour VOUS et vous donnons la chance abordable de acquérir des connaissances auprès de traders professionnels expérimentés.",
            //         "GET STARTED": "COMMENCER",
            //         "Trade setups": "Configurations commerciales",
            //         "per year": "par an",
            //         "ONCE": "UNE FOIS",
            //         "a week": "une semaine",
            //         "Our average PNL on a annual base:": "Notre PNL moyen sur une base annuelle:",
            //         "Trading and learning": "Trading et apprentissage",
            //         "With no extra nonsense or obligations.": "Sans aucun autre non-sens ou obligations",
            //         "Stay updated, stay one step ahead. We like to keep it clean and simple. Which means there is no place for chatting or debating in our private channel. One way information. One educational trade a week, every week!": "Restez à jour, restez en avance. Nous aimons le garder propre et simple. Cela signifie qu'il n'y a pas de place pour discuter ou débattre dans notre canal privé. Une seule voie d'information. Une transaction éducative par semaine, toutes les semaines!",
            //         "CHOOSE": "CHOISIR",
            //         "CHOOSE YOUR PLAN": "CHOISISSEZ VOTRE PLAN",
            //         "Trust": "Confiance",
            //         "Privacy Rules": "Règles de confidentialité",
            //         "Terms & conditions": "Conditions générales",
            //         "Let's stay social": "Restons sociaux",
            //         "Qua  rterly": "Trimestriel",
            //         "Get exclusive access to our community for 3 months.": "Obtenez un accès exclusif à notre communauté pendant 3 mois.",
            //         "Get 13 educational trade setups for 0.30 cents each.": "Obtenez 13 configurations commerciales éducatives pour seulement 0,30 centime chacune.",
            //         "Get access to our exclusive discord group.": "Obtenez accès à notre groupe discord exclusif.",
            //         "Pay every quarter.": "Payez chaque trimestre.",
            //         "annual": "Annuellement",
            //         "Stay informed with real-time updates and expert analysis all year round!": "Restez informé avec des mises à jour en temps réel et une analyse d'experts tout au long de l'année!",
            //         "Get 52 educational trade setups for only 0.25 cents each.": "Obtenez 52 configurations commerciales éducatives pour seulement 0,25 centime chacune.",
            //         "One-time payment, full package.": "Paiement unique, package complet.",
            //         "Most chosen worldwide.": "Le plus souvent choisi dans le monde entier.",
            //         "Best value for money.": "Le meilleur rapport qualité-prix.",
            //         "Welcome to The Weekly Trade! We are dedicated to providing educational and informational trade setups for our members. Our team of experts shares weekly trade setups, with a focus on keeping the information accessible and informative for all.": "Bienvenue à The Weekly Trade! Nous sommes dédiés à fournir des configurations commerciales éducatives et informatives pour nos membres. Notre équipe d'experts partage des configurations commerciales hebdomadaires, en veillant à ce que l'information soit accessible et informative pour tous.",
            //         "We started this The Weekly Trade discord server with the aim of providing a space where traders could receive high-quality trade setups without having to pay exorbitant fees. Our belief is that everyone should have access to the information they need to succeed in the markets, and that's why we are committed to providing it for a small fee.": "Nous avons lancé ce serveur Discord The Weekly Trade dans le but de fournir un espace où les traders peuvent recevoir des configurations de commerce de haute qualité sans avoir à payer des frais exorbitants. Notre croyance est que tout le monde devrait avoir accès à l'information dont ils ont besoin pour réussir sur les marchés, et c'est pourquoi nous nous engageons à le fournir pour une petite somme.",
            //         "Please note that all the trade setups provided in our discord server are for educational purposes only and are not financial advice. We encourage all members to conduct their own research and make their own investment decisions.": "Veuillez noter que tous les paramètres de trade fournis dans notre serveur Discord ne sont destinés qu'à des fins éducatives et ne constituent pas de conseils financiers. Nous encourageons tous les membres à mener leur propre recherche et à prendre leurs propres décisions d'investissement.",
            //         "We hope you find our community a valuable resource in your trading journey and we look forward to sharing our insights and experiences with you.": "Nous espérons que vous trouverez notre communauté une resource précieuse dans votre parcours de trading et nous attendons avec impatience de partager nos idées et nos expériences avec vous.",
            //         "About us": "À propos de nous",
            //         "we are working on it": "Nous travaillons dessus",
            //     }
            // },
            // lng: "en",
            // fallbackLng: "en",
            interpolation: {
                escapeValue: false
            }
        }
    });

export default i18n;
