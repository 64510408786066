import { useTranslation } from 'react-i18next';
import './trust-component.styles.scss';
import MobileMenu from '../../components/mobile-menu/mobile-menu.component';

const cookieUsage = [
    "Ensure our website functions properly.",
    "Personalize your experience on our website.",
    "Understand how our website is being used.",
    "Provide marketing and advertising."
];

const cookies = [
    {
      title: 'Strictly necessary cookies',
      description: 'These cookies are essential for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website or use a shopping cart.'
    },
    {
      title: 'Performance cookies',
      description: 'These cookies allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users find what they are looking for easily.'
    },
    {
      title: 'Functionality cookies',
      description: 'These cookies are used to recognize you when you return to our website. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).'
    },
    {
      title: 'Targeting cookies',
      description: 'These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.'
    },
    {
      title: 'Third-party cookies',
      description: "We use third-party cookies on our website, including Google Analytics. Google's privacy policy is available at: https://policies.google.com/privacy."
    }
];

const CoockiePolicyComponent = () => {
    const {t} = useTranslation();

    return (
        <main className='main-container trustWrapper'>
            <div className='trustContainer'>
                <h1>{t("Coockie policy")}</h1>
                <p>This Cookie Policy explains how we use cookies and similar tracking technologies on our website. By using our website, you consent to the use of cookies in accordance with this Cookie Policy.</p>
                
                <div className='sectionBlock'>
                    <h2>What are cookies?</h2>
                    <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
                </div>

                <div className='sectionBlock'>
                    <h2>How we use cookies</h2>
                    <h3>We use cookies to:</h3>
                    {cookieUsage && cookieUsage.map((item, index) => (
                            <ul key={index}>
                                <li>{item}</li>
                            </ul>
                        ))
                    }
                </div>

                <div className='sectionBlock'>
                    <h2>Types of cookies we use</h2>
                    <h3>We use the following types of cookies:</h3>
                    {cookies && cookies.map((item, index) => (
                            <ul key={index}>
                                <li>{item.title}: {item.description}</li>
                            </ul>    
                        ))
                    }
                </div>

                <div className='sectionBlock'>
                    <h2>Third-party cookies</h2>
                    <h3>We use third-party cookies on our website, including:</h3>
                    <ul>
                        <li>Google Analytics: We use Google Analytics to analyze the use of our website. 
                            Google Analytics gathers information about website use by means of cookies. 
                            The information gathered relating to our website is used to create reports about the use of our website. 
                            Google's privacy policy is available at: <a className='sectionLink' href='https://policies.google.com/privacy' target='_blanck'>Google policies</a>.
                        </li>
                    </ul>
                </div>

                <div className='sectionBlock'>
                    <h2>Managing cookies</h2>
                    <p>Most web browsers allow you to manage or delete cookies through their settings. To learn more about how to manage your cookies, please refer to your browser's help section.</p>
                </div>

                <div className='sectionBlock'>
                    <h2>Changes to this Cookie Policy</h2>
                    <p>We may update this Cookie Policy from time to time to reflect changes to the cookies we use or for other operational, legal or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our use of cookies.</p>
                </div>
            </div>
            <MobileMenu />
        </main>
    );
};

export default CoockiePolicyComponent;
