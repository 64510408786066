import { useTranslation } from 'react-i18next';
import './trust-component.styles.scss';
import MobileMenu from '../../components/mobile-menu/mobile-menu.component';
import TrustSubsectionComponent from './trust-subsection-component';

const TrustComponent = ({data, title}) => {
    const {t} = useTranslation();

    return (
        <main className='main-container trustWrapper'>
            <div className='trustContainer'>
                <h1>{t(title)}</h1>
                {data && data.map((item, index) => {
                    return (
                        <ul className='articleList' key={index}>
                            <li><h2>{item.title}</h2></li>
                            <li className='articleItem'>{item.content}</li>
                            { item.definitions && 
                                <div className="table-responsive">
                                <table className="table">
                                  <tbody>
                                    {item.definitions.map((definition, index) => (
                                      <tr key={index}>
                                        <td>{definition.term}</td>
                                        <td className='sectionContent'>
                                          {definition.content}
                                          {definition.link && (
                                            <a href={definition.link.href} className='sectionLink' target='_blanck'>{definition.link.name}</a>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            }
                            { item.sections && item.sections.map((section, index) => {
                                return (
                                    <ul className='articleList subList' key={index}>
                                        {
                                            section.link ? 
                                                <li className='articleItem' dangerouslySetInnerHTML={
                                                    {
                                                        __html: `<h3 class='sectionTitle'>
                                                        ${section.sectionNumber}:</h3><p class='sectionContent'>
                                                        ${section.content}
                                                        ${section.link ? ` <a href='${section.link.href}' class='sectionLink' target='_blanck'>
                                                        ${section.link.name}</a>` : ''}</p>`
                                                    }
                                                    }>
                                                </li>
                                            :
                                                <li className='articleItem'>
                                                    <h3 className='sectionTitle'>{section.sectionNumber}:</h3>
                                                        <p className='sectionContent'>{section.content}</p>
                                                </li>
                                        }
                                        {section.extraContent && section.extraContent.map((extraSection) =>
                                            <li className='extraSection'>{extraSection}</li>
                                        )}
                                        { section.subsections && section.subsections.map((subsection, key) => (
                                                <ul className='articleList subList' key={key}>                                                
                                                    <TrustSubsectionComponent data={subsection} key={key} />
                                                    { subsection.subsections && subsection.subsections.map((subsection, key) =>  (
                                                            <ul className='articleList subList' key={key}>
                                                                <TrustSubsectionComponent data={subsection} key={key} />
                                                                { subsection.subsections && subsection.subsections.map((subsection, key) =>  (
                                                                        <ul className='articleList subList' key={key}>
                                                                            <TrustSubsectionComponent data={subsection} key={key} />
                                                                        </ul>
                                                                    )
                                                                )}                                            
                                                            </ul>
                                                        )
                                                    )}                                            
                                                </ul>
                                            )
                                        )}
                                    </ul>
                                )
                            })}
                        </ul>
                    )})
                }
            </div>
            <MobileMenu />
        </main>
    );
};

export default TrustComponent;
