import { Link } from 'react-router-dom';
import './navigation-bar.styles.scss';
import HeaderLinks from '../../components/navigation-components/header-links-component/header-links.component';
import logo from '../../assets/svg/twt-logo.svg';
// import LanguagePicker from '../../components/navigation-components/language-picker-component/language-picker.component';

const NavigationBar = () => {
    return(
        <header className='navigation-bar container'>
            <Link to="/">
                <img src={logo} alt='twt logo' className='navigation-bar__logo'/>
            </Link>
            <div className='navigation-bar_content-wrapper'>
                {/* <div className='navigation-bar__languagepicker'>
                    <LanguagePicker />
                </div> */}
                <HeaderLinks></HeaderLinks>
            </div>
        </header>
    );
};

export default NavigationBar;
