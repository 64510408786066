// import LanguagePicker from '../language-picker-component/language-picker.component';
import './dropdown.styles.scss';
import {HashLink as Link} from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import twitterLogo from '../../../assets/svg/twitter.svg';

/**
 * @param {isShown} = boolean variable that checks if the dropdown is displayed or not 
 */
const DropDown = ({isShown, handleClick}) => {
    const {t} = useTranslation();

    return (
        <div className='navigation-bar__dropdown'>
            <Link 
                to='/#trades' 
                className={`navigation-bar-link 
                ${isShown ? 'show-list' : 'no-show-list'}`}
                alt='link to trades'
                onClick={handleClick}
                >
                Trades
            </Link>
            <Link
                to='/#discord' 
                className={`navigation-bar-link 
                ${isShown ? 'show-list' : 'no-show-list'}`}
                alt='link to discord'
                onClick={handleClick}
                >
                Discord
            </Link>
            {/* <Link
                to='/#referral'
                className={`navigation-bar-link 
                ${isShown ? 'show-list' : 'no-show-list'}`}
                alt='link to plans'
                onClick={handleClick}
            >
                Referral
            </Link> */}
            <Link
                to='https://discord.gg/JgusxAKSKt'
                className={`navigation-bar-link 
                ${isShown ? 'show-list' : 'no-show-list'}`}
                alt='link to plans'
                target='_blanck'
                onClick={handleClick}
            >
                Get Started
            </Link>
            {/* <div className={'navigationbar__language-picker'}>
                <LanguagePicker/>
            </div> */}
            <div className='dropdownFooter'>
                <h3 className='footerItemHeader'>{t("Let's stay social")}</h3>
                <ul className='footerSocials'>
                    <li className='itemLink'>
                        <a href="https://twitter.com/The_weeklytrade" target="_blank" rel="noreferrer">
                            <img src={twitterLogo} alt="Twitter" width='40' height='40' />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default DropDown;
