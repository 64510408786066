import './partner.styles.scss'
import bybitImg from '../../assets/webp/Bybit_Logotype_Darkmode-Duo.webp';

const partners = [
    {
        title: 'Bybit',
        link: 'https://www.bybit.com/sign-up?affiliate_id=55358&group_id=91813&group_type=1',
        logo: bybitImg,

    }
];

const PartnerComponent = () => {
    return (
        <div className='partnerContainer'>
            <h2 className='partnersTitle'>Partnered with the best</h2>
            <div className='partnerWrapper'>
                {partners.map((partner, index) => (
                    <a href={partner.link} key={index}>
                        <img src={partner.logo} alt={partner.title} className='partnerImg'/>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default PartnerComponent;