import { useTranslation } from 'react-i18next';
import './about.styles.scss';
import MobileMenu from '../../components/mobile-menu/mobile-menu.component';

const About = () => {
    const {t} = useTranslation();

    return (
        <main className="aboutWrapper">
            <div className='aboutContainer'>
                <h2 className='aboutHeader'>{t("About us")}</h2>
                <div>
                    <p>
                        {t("Welcome to The Weekly Trade! We are dedicated to providing educational and informational trade setups for our members. Our team of experts shares weekly trade setups, with a focus on keeping the information accessible and informative for all.")}
                    </p>
                    <br/>
                    <p>
                        {t("We started this The Weekly Trade discord server with the aim of providing a space where traders could receive high-quality trade setups without having to pay exorbitant fees. Our belief is that everyone should have access to the information they need to succeed in the markets, and that's why we are committed to providing it for a small fee.")} 
                    </p>
                    <br/>
                    <p> 
                        {t("Please note that all the trade setups provided in our discord server are for educational purposes only and are not financial advice. We encourage all members to conduct their own research and make their own investment decisions.")} 
                    </p>
                    <br/>
                    <p>
                        {t("We hope you find our community a valuable resource in your trading journey and we look forward to sharing our insights and experiences with you.")}
                    </p>
                </div>
            </div>
            <MobileMenu />
        </main>
    );
};

export default About;
