import './footer.styles.scss';
import twtLogo from '../../assets/svg/twt-logo.svg';
import twitterLogo from '../../assets/svg/twitter.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FooterComponent = () => {
    const {t} = useTranslation();

    return (
        <footer className='footer'>
            <div className='footerContainer'>
                <div >
                    <Link className='itemLink' to="/">
                        <img src={twtLogo} alt="The Weekly Trade logo" width='160' height='160'/>
                    </Link>
                    <p className='copyRightDesktop'>© 2023 - The Weekly Trade</p>
                </div>
                <div className='footerItem'>
                    <h3 className='footerItemHeader'>{t("Trust")}</h3>
                    <ul className='footerTrust'>
                        <li>
                            <Link
                                className='whiteText'
                                to="/about-us"
                                onClick={() => {
                                    window.scroll(0, 0);
                                }}
                            >
                                    {t("About us")}
                            </Link>
                        </li> 
                        <li>
                            <Link
                                className='whiteText'
                                to="/general-terms-conditions"
                                onClick={() => {
                                    window.scroll(0, 0);
                                }}
                            >
                                {t("General terms & conditions")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className='whiteText'
                                to="/privacy-rules"
                                onClick={() => {
                                    window.scroll(0, 0);
                                }}
                            >
                                {t("Privacy Rules")}
                            </Link>
                        </li> 
                        <li>
                            <Link
                                className='whiteText'
                                to="/refund-policy"
                                onClick={() => {
                                    window.scroll(0, 0);
                                }}
                            >
                                {t("Refund policy")}
                            </Link>
                        </li> 
                        <li>
                            <Link
                                className='whiteText'
                                to="/cookie-policy"
                                onClick={() => {
                                    window.scroll(0, 0);
                                }}
                            >
                                {t("Cookie policy")}
                            </Link>
                        </li> 
                    </ul>
                </div>
                <div className='footerItem'>
                    <h3 className='footerItemHeader'>{t("Let's stay social")}</h3>
                    <ul className='footerSocials'>
                        <li className='itemLink'>
                            <a href="https://twitter.com/The_weeklytrade" target="_blank" rel="noreferrer">
                                <img src={twitterLogo} alt="Twitter" width='30' height='30' />
                            </a>
                        </li>
                    </ul>
                </div>
                <p className='copyRightMobile'>© 2023 - The Weekly Trade</p>
            </div>
        </footer>
    )
};

export default FooterComponent;
