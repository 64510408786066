import DiscordComponent from '../../components/discord-component/discord.component';
import Hero from '../../components/hero-component/hero.component';
// import HowTwtWorksComponent from '../../components/how-twt-works/how-twt-works-component';
import Intro from '../../components/intro-component/intro.component';
import MobileMenu from '../../components/mobile-menu/mobile-menu.component';
import PartnerComponent from '../../components/partner-component/partner-component';
// import PlansComponent from '../../components/plans-compoment/plans.component';
// import ReferralComponent from '../../components/referral-component/referral.component';
import TradesComponent from '../../components/trades-component/trades.component';
import './home.styles.scss';
// import { Helmet } from 'react-helmet-async';

const Home = () => {
    // const title = "The Weekly Trade";
    // const description = 'Gain access to professional trade setups without breaking the bank with our affordable trading platform. Join our trading community and learn from experienced traders. We make trading easy and simple for you, with a focus on providing knowledge and education to help you succeed in the market.';
    // const keywords = 'Trade setups, Professional traders, Affordable trading, Trading community, Knowledge gain, Experienced traders, Easy trading, Simple trading, Trading platform, Community groups, The Weekly Trade, TWT';
    // const url = "https://www.theweeklytrade.io/";
    // const image = "../../assets/webp/twt_logo.webp";


    return (
        <>
            {/* <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta property="og:url" content={url} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <meta property="twitter:image:width" content="500" />
                <meta property="twitter:image:height" content="500" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet> */}
            <main className='main-container'>
                <Hero />
                <Intro />
                {/* <HowTwtWorksComponent /> */}
                <TradesComponent />
                <DiscordComponent />
                {/* <ReferralComponent /> */}
                {/* <PlansComponent /> */}
                <PartnerComponent />
                <MobileMenu />
            </main>
        </>
    );
};

export default Home;
