import './hero.styles.scss';
import Noorderlicht from '../../assets/webp/noorderlicht.webp'
import CtaButton from '../../atom/cta-button/ctaButton.component';
import { useTranslation } from 'react-i18next'; 

const Hero = () => {
    const { t } = useTranslation();

    return (
        <section className="hero-container">
            <header className='hero-header'>
                <h1 className='hero-header__title'>{t("Weekly setups now also available for you")}</h1>
            </header>
            <div className="secondSubtitle-content">
                <p className="secondSubtitle-content__content">
                    {t("The platform for all those out there who can’t afford expensive community groups about trading.")} <br />
                    {t("We make it easy and simple for YOU and give you an afforable chance to gain knowledge from experienced professional traders.")}
                </p>
                <CtaButton />
            </div>            
            <img 
                src={Noorderlicht}
                className='hero-img'
                alt=''
            />
            <div className='background-gradient'></div>
        </section>
    );
};

export default Hero;
