import './discord.styles.scss';
import DiscordSever from '../../assets/webp/discordServer.webp';
import DiscordMessage from '../../assets/webp/discord-trade-setup.webp';
import { useTranslation } from 'react-i18next';
import CtaButton from '../../atom/cta-button/ctaButton.component';

const DiscordComponent = () => {
    const {t} = useTranslation();

    return (
        <section className='discordContainer' id='discord'>
            <div className='discord'>
                <div className='discordImage'>
                    <img src={DiscordSever} alt='discord server' className='discordImageServer'/>
                    {
                        <img src={DiscordMessage} alt='discord message' className='discordImageMessage' /> 
                    }
                </div>
            </div>
            <div className='contentDiscord'>
                <h2 className='contentDiscordTitle'>Discord</h2>
                <p className='contentSubtitle'>
                    {t("Stay updated, stay one step ahead. We like to keep it clean and simple. Which means there is no place for chatting or debating in our private channel. One way information. One educational trade a week, every week!")}
                </p>
                <CtaButton />
            </div>
        </section>
    );
};

export default DiscordComponent;
